<template>
  <v-app class="education"> 
    <v-banner
      single-line
      :height="screenHeight"
      class="banner"
    >
      <video ref="videos" id="video" loop="loop" muted="muted"
        preload="auto" autoplay="autoplay"
        poster="https://h5.ophyer.cn/official_website/education/banner-bg-01.png"
        type="video/mp4"
        class="video" src="https://h5.ophyer.cn/official_website/education/education.mp4" style="height: 100%;object-fit: cover"></video>
      <div class="video-up"></div>
       
      <div class="banner-box" ref="bannerTitle">
        <div class="banner-nav">
          <img src="@/assets/login/logo_bai.png" alt="">
          <p>400-6189-949</p>
        </div>
        <img class="jt" src="https://h5.ophyer.cn/official_website/education/education-banner-jt.png" alt="">
    </div>
      <div class="bottom"></div>
    </v-banner>

    <div class="banner-con">
      <img src="https://h5.ophyer.cn/official_website/education/banner-text.png" alt="">
      <p class="banner-text">随着元宇宙技术的不断完善，大力推动各行各业的快速发展，在科技不断创新发展的同时，市场对元宇宙相关技术人才有巨大的需求。</p>
      <p class="banner-text">飞天云动作为元宇宙行业的先行者，结合自身产业优势、先进技术产品、优质课程体系、行业技术专家授课一体化，专注元宇宙人才培养。通过科学、多样化的教学模式，打造数字化人才成长沃土，助力科技人才生长，赋能企业科技发展，推动元宇宙数字化落地。</p>
      
    </div>

    <!-- 平台优势 -->
    <v-card class="education-warp warp1" :flat="true">
      <div class="public-titles">
        <p>PLATFORM ADVANTAGE</p>
        <div>
          <span>平台优势</span>
        </div>
      </div>
      <div class="con">
        <ul>
          <li v-for="(v, k) in platformAdvantages" :key="k">
            <div class="box" :style="{backgroundImage: 'url('+v.bg+')'}">
              <img :src='v.icon' />
              <v-card-text> {{ v.text }} </v-card-text>
            </div>
          </li>
        </ul>
        <!-- <v-row no-gutters justify='center'>
          <v-col v-for="(v, k) in platformAdvantages" :key="k" cols="3">
            <div class="box" :style="{backgroundImage: 'url('+v.bg+')'}">
              <img :src='v.icon' />
              <v-card-text> {{ v.text }} </v-card-text>
            </div>
          </v-col>
        </v-row> -->
      </div>
    </v-card>

    <!-- 课程产品 -->
    <v-card class="education-warp warp2" :flat="true">
      <div class="public-titles">
        <p>COURSE PRODUCT</p>
        <div>
          <span>课程产品</span>
        </div>
      </div>
      <div class="carousel-container">
        <div class="c-item-1 box-shadow" v-for='(item,ind) in courseProducts' :key='ind' :class="courseProductsIndex === (ind+1) ? 'c-item-hover':''" @mouseover="courseProductsIndex =(ind+1);">
        <div class="c-item-cover">  
          <v-img :src='item.img' width="248"></v-img>    
        </div>     
        <div class="coverleft">
            <p>{{item.sub}}</p>
          </div>     
        </div>
      </div>
    </v-card>

    <!-- 面向人群 -->
    <v-card class="education-warp warp3">
      <div class="public-titles">
        <p>FOR THE CROWD</p>
        <div>
          <span>面向人群</span>
        </div>
      </div>
      <div class="warp-box d-flex justify-center">
        <v-card elevation="0">
          <v-tabs v-model="advantageTab" hide-slider vertical>
              <v-tab
                v-for="(item,index) in faceCrowd"
                :key="index"
                :class="['mr-3 justify-start',(faceCrowd.length-1) != index?'mb-2':'']"
              >
                <p @mouseover="mouseover" @mouseleave="mouseleave">
                  <span>{{ item.index }}</span>
                  <span></span>
                  <span>{{ item.indexName }}</span>
                </p>
              </v-tab>
              <v-tab-item
                v-for="item in faceCrowd"
                :key="item.tab"
                transition="fade-transition"
              >
                <v-card style="box-shadow: none;border-radius: 0 30px 30px 0;">
                  <v-img
                    :src="item.src"
                    class="white--text align-left flex-grow-0"
                    width="1020"
                    height="560"
                  >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        size="80"
                        color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                    <div class="d-flex flex-column lavbg">
                      <p>{{ item.imgbgTitle }}</p>
                      <p>{{ item.imgbgText }}</p>
                    </div>
                  </v-img>
                </v-card>
              </v-tab-item>
          </v-tabs>
        </v-card>
      </div>
    </v-card>

    <!-- 就业机会 -->
    <v-card class="education-warp warp4" :flat="true">
      <div class="public-titles">
        <p>JOB OPPORTUNITY</p>
        <div>
          <span>就业机会</span>
        </div>
      </div>
      <div class="box">
        <ul class="carousel-nav-ul">
          <li 
            v-for="(v, k) in employmentOpportunities" 
            :key="k" 
            :class="[employmentOpportunitiesActive == k ? 'active' : '']" 
            @click="employmentOpportunitiesActive = k">
            <img :src="v.icon" alt="">
            <span>{{ v.name }}</span>
          </li>
        </ul>
        <ul class="carousel-con-ul" v-if="employmentOpportunitiesActive === 0">
          <li v-for="(v, k) in employmentOpportunities[employmentOpportunitiesActive].list" :key="k">
            <img :src="v.img" alt="">
            <p>{{ v.title }}</p>
          </li>
        </ul>

        <v-carousel v-else :reverse="false" height="330" :hide-delimiters="true" :continuous="false" :show-arrows="false" :cycle="false">
          <v-carousel-item v-for="(v, k) in employmentOpportunities[employmentOpportunitiesActive].list" :key="k">
            <ul class="carousel-ul">
              <li v-for="(v2, k2) in v" :key="k2">
              <img :src="v2" alt=""></li>
            </ul>
          </v-carousel-item>
       </v-carousel>
      </div>
    </v-card>

    <!-- 品牌荣誉 -->
    <v-card class="education-warp warp5" :flat="true">
      <div class="public-titles">
        <p>BRAND HONORS</p>
        <div>
          <span>品牌荣誉</span>
        </div>
      </div>
      <div class="swiper-box">
        <swiper ref="mySwiper" :options="swiperOption">
          <swiper-slide v-for="(v,k) in brandHonors" :key="k">
            <div class="con-d">
              <img :src="v.img" />
            </div>
            <p>{{ v.title }}</p>
          </swiper-slide>

          <div class="swiper-button-next" slot="button-next">
            <img src="https://h5.ophyer.cn/official_website/education/brandHonors-next.png" alt="">
          </div>
          <div class="swiper-button-prev" slot="button-prev">
            <img src="https://h5.ophyer.cn/official_website/education/brandHonors-prev.png" alt="">
          </div>
        </swiper>
      </div>
    </v-card>

    <!-- 平台优势 -->
    <v-card class="education-warp warp6" :flat="true">
      <div class="public-titles">
        <p>MEDIA REPORTS</p>
        <div>
          <span>媒体报道</span>
        </div>
      </div>
      <div class="con">
        <ul>
          <li v-for="(v, k) in mediaCoverage" :key="k" :class="['li'+k]">
            <span class="time">{{ v.time }}</span>
            <img :src="v.logo" alt="">
            <p class="news-title">{{ v.newsTitle }}</p>
            <div class="new-list">
              <p v-for="(v2,k2) in v.newList" :key="k2" v-html="v2"></p>
            </div>
            <button @click="open(v.url)">查看全部</button>
          </li>
        </ul>
      </div>
    </v-card>


    <v-footer height="350" color="#151C29" class="justify-center align-center">
      <v-container class="pa-0 footer-container">
        <v-row class="ma-0 d-flex justify-space-between align-center" :class="isIE == true ? 'isIE' : ''">
          <v-col cols="3" class="d-flex justify-start pa-0">
            <v-card color="transparent" flat class="pt-2">
              <v-img class="ml-n1" width="160" alt="logo" contain src="@/assets/login/logo_bai.png" />
              <v-card-text class="pa-0 mt-14">
                <v-icon dense style="color: #AEB8C8">fas fa-phone-alt</v-icon>
                <span class="ml-2 font-size-16" style="color: #AEB8C8">4006189949</span>
              </v-card-text>
              <v-card-text class="pa-0 mt-5">
                <v-icon dense style="color: #AEB8C8">fas fa-map-marker-alt</v-icon>
                <span class="ml-3 font-size-14" style="color: #AEB8C8">北京朝阳区广渠路3号竞园艺术中心商8 </span>
              </v-card-text>
            </v-card>
          </v-col>
          <span class="ml-3 font-size-14" style="color: #AEB8C8;margin-top: 157px;position: relative;left: -100px;">© COPYRIGHT 2022 . ALL RIGHTS RESERVED. 京公网安备11010502037637号京ICP备09083236号-2 </span>
          <v-col cols="1" class="pa-0">
            <v-card color="transparent" flat class="mt-5">
              <v-card-text class="d-flex justify-center pa-0 qr-code">
                <v-img alt="二维码" contain src="../../../public/img/icons/menu/qrCode.png" width="108" max-width="108" height="108" max-height="108" />
              </v-card-text>
              <v-card-text class="d-flex justify-center pa-0 mt-3 content-info font-size-16" style="color: #AEB8C8;">官方公众号</v-card-text>
            </v-card>
          </v-col>
        </v-row>
 
      </v-container>
    </v-footer>

    <div class="suspend-btn">
      <div class="hover-group">
        <div class="group-btn">
          <v-row class="pa-0" @click.stop="currentHover = 'phone'" @mouseenter="currentHover = 'phone'" @mouseleave="currentHover = ''">
            <v-img width="50" height="50" contain src="../../../public/img/icons/menu/phone.png"></v-img>
          </v-row>
          <div class="d-flex justify-center">
            <div style="width: 30%">
              <v-divider></v-divider>
            </div>
          </div>
          <!-- <v-row class="pa-0" @click.stop="currentHover = 'tryout'">
            <v-img width="50" height="50" contain src="../../../public/img/icons/menu/tryout.png"></v-img>
          </v-row> -->
          <div class="d-flex justify-center">
            <div style="width: 30%">
              <v-divider></v-divider>
            </div>
          </div>
          <v-row class="pa-0" @click.stop="currentHover = 'follow'" @mouseenter="currentHover = 'follow'" @mouseleave="currentHover = ''">
            <v-img width="50" height="50" contain src="../../../public/img/icons/menu/follow.png"></v-img>
          </v-row>
        </div>
        <div class="hover-content" @click.stop>
          <transition name="fade" mode="out-in">
            <div class="content-phone" @mouseenter="currentHover = 'phone'" @mouseleave="currentHover = ''" v-show="currentHover === 'phone'">
              <v-card color="transparent" flat>
                <v-row class="pa-0">
                  <div class="d-inline width-40">
                    <v-avatar size="40" rounded-2>
                      <v-img contain src="../../../public/img/icons/menu/cooperation.png"></v-img>
                    </v-avatar>
                  </div>
                  <div class="d-inline ml-3 mt-n3">
                    <v-card color="transparent" flat>
                      <v-card-title class="pa-0 font-size-20 mt-2">商户合作</v-card-title>
                      <v-card-text class="pa-0 mt-3 font-size-16" style="line-height: 1.4">business@ophyer.com</v-card-text>
                    </v-card>
                  </div>
                </v-row>
                <v-row class="pa-0 mt-8">
                  <div class="d-inline width-40">
                    <v-avatar size="40" rounded-2>
                      <v-img width="40" height="40" contain src="../../../public/img/icons/menu/consult.png"></v-img>
                    </v-avatar>
                  </div>
                  <div class="d-inline ml-3 mt-n3">
                    <v-card color="transparent" flat>
                      <v-card-title class="pa-0 font-size-20 mt-2">产品咨询/加盟</v-card-title>
                      <v-card-text class="pa-0 mt-3 font-size-16" style="line-height: 1.4">4006189949</v-card-text>
                    </v-card>
                  </div>
                </v-row>
              </v-card>
            </div>
          </transition>
       
          <transition name="fade" mode="out-in">
            <div class="content-follow" @mouseenter="currentHover = 'follow'" @mouseleave="currentHover = ''" v-show="currentHover === 'follow'">
              <v-card color="transparent" flat>
                <v-row class="pa-0 justify-center">
                  <v-img contain src="https://h5.ophyer.cn/official_website/education/qrCode.png" width="220" height="220"></v-img>
                  <v-card-title class="font-size-16 pa-0">更多课程福利，快扫码咨询吧</v-card-title>
                </v-row>
              </v-card>
            </div>
          </transition>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div class="hover-btn" v-show="officialWebsite.showReturnTip" @click.stop="$vuetify.goTo(0, {})">
          <v-img src="../../../public/img/icons/menu/returnTop.png"></v-img>
        </div>
      </transition>
    </div>

    <!-- <router-view></router-view> -->
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import { browserVersion } from '@/utils/utils'

let _this;
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "education",
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapState(['officialWebsite'])
  },
  data() {
    return {
      screenHeight: window.innerHeight,
      courseProductsIndex: 1,
      currentIndex: 0,
      advantageTab: 0,
      timer: null,
      employmentOpportunitiesActive: 0,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 50,
        speed: 3000,
        autoplay : {
          delay: 6000
        },
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        prevButton:'.swiper-button-prev',
        nextButton:'.swiper-button-next',
      },
      currentHover: '',
      isIE: browserVersion(),

      platformAdvantages: [
        {
          icon: 'https://h5.ophyer.cn/official_website/education/platformAdvantages-icon-01.png',
          bg: 'https://h5.ophyer.cn/official_website/education/platformAdvantages-bg1.png',
          text: '独家自研实训平台，优化学习路径，实现测学练一体化，提升学习效率'
        },
        {
          icon: 'https://h5.ophyer.cn/official_website/education/platformAdvantages-icon-02.png',
          bg: 'https://h5.ophyer.cn/official_website/education/platformAdvantages-bg2.png',
          text: '课程内容全面升级，紧贴行业热点，更科学合理化，助力优质人才成长'
        },
        {
          icon: 'https://h5.ophyer.cn/official_website/education/platformAdvantages-icon-03.png',
          bg: 'https://h5.ophyer.cn/official_website/education/platformAdvantages-bg3.png',
          text: '辅导式学习体系，全方位督导学习，学习成果可视化，见证自己的成长过程'
        },
        {
          icon: 'https://h5.ophyer.cn/official_website/education/platformAdvantages-icon-04.png',
          bg: 'https://h5.ophyer.cn/official_website/education/platformAdvantages-bg4.png',
          text: '行业大咖师资带队授课，让技术大牛，助力实现你的职业梦'
        },
      ],
      courseProducts: [
        {
          img: 'https://h5.ophyer.cn/official_website/education/courseProducts-img1-big-01.png',
          width: '30px',
          sub: '虚拟引擎开发',
        },
        {
          img: 'https://h5.ophyer.cn/official_website/education/courseProducts-img1-big-02-01.png',
          width: '30px',
          sub: 'Unity游戏开发',
        },
        {
          img: 'https://h5.ophyer.cn/official_website/education/courseProducts-img1-big-03.png',
          width: '30px',
          sub: '全行业UI/UE设计'
        },
        {
          img: 'https://h5.ophyer.cn/official_website/education/courseProducts-img1-big-04.png',
          width: '32px',
          sub: '3D模型设计 '
        }
      ],
      faceCrowd: [
        {
          index: '01',
          indexName: "职场新人",
          src: 'https://h5.ophyer.cn/official_website/education/faceCrowd-img-01.png',
          imgbgTitle: '应届毕业生',
          imgbgText: '对互联网行业感兴趣，期望可以从事IT技术研发相关工作，快人一步，快速入门未来发展迷茫，期望拥有一技之长，快速就业，实现高薪职业',
        },
        {
          index: '02',
          indexName: "零基础",
          src: 'https://h5.ophyer.cn/official_website/education/faceCrowd-img-02.png',
          imgbgTitle: '零基础想学一技之长，实现高薪就业',
          imgbgText: '零基础想要进入互联网行业，选择目前热门行业，挑战高薪对AI、元宇宙相关技术感兴趣，想要学习掌握编程技能，提升个人技能水平',
        },
        {
          index: '03',
          indexName: "转行求职",
          src: 'https://h5.ophyer.cn/official_website/education/faceCrowd-img-03.png',
          imgbgTitle: '转行求职，选择高薪职业',
          imgbgText: '对于目前的工作不太满意，发展无前景，想要从事前景好、薪资高新的行业，规划好的未来改变个人现状，想学习行业先进技术，挑战高薪，成就自我价值',
        },
        {
          index: '04',
          indexName: "能力提升",
          src: 'https://h5.ophyer.cn/official_website/education/faceCrowd-img-04.png',
          imgbgTitle: '期望再次提升的IT从业者',
          imgbgText: '期望拓展自己的技术领域，提升自己的职业天花板，拓宽职业领域，提升技能天花板学习热门先进技术，期望升职加薪，丰富个人项目经历，冲击行业TOP公司',
        },
      ],
      employmentOpportunities: [
        {
          icon: 'https://h5.ophyer.cn/official_website/education/employmentOpportunities-icon-01.png',
          name: '行业领域',
          list: [
            {
              title: '游戏开发',
              img: 'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hyly-list-01.png'
            },
            {
              title: '动画影视',
              img: 'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hyly-list-02.png'
            },
            {
              title: '建筑与工业',
              img: 'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hyly-list-03.png'
            },
            {
              title: 'AR / VR / XR',
              img: 'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hyly-list-04.png'
            }
          ]
        },
        {
          icon: 'https://h5.ophyer.cn/official_website/education/employmentOpportunities-icon-02.png',
          name: '合作企业',
          list: [
            [
              'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hzqy-list-bd.png',
              'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hzqy-list-zfb.png',
              'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hzqy-list-albb.png',
              'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hzqy-list-wx.png',
              'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hzqy-list-jd.png',
              'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hzqy-list-mg.png',
              'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hzqy-list-qjdy.png',
              'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hzqy-list-nike.png',
              'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hzqy-list-zgyz.png',
              'https://h5.ophyer.cn/official_website/education/employmentOpportunities-hzqy-list-dz.png',
            ],
           
          ]
        }
      ],
      brandHonors: [
        {
          title: '元宇宙行业突出贡献奖',
          img: 'https://h5.ophyer.cn/official_website/education/brandHonors-img-02.png'
        },
        {
          title: '年度优秀AR创新应用',
          img: 'https://h5.ophyer.cn/official_website/education/brandHonors-img-03.png'
        },
        {
          title: '行业领军企业',
          img: 'https://h5.ophyer.cn/official_website/education/brandHonors-img-04.png'
        },
        {
          title: '京东ARVR共赢合作伙伴',
          img: 'https://h5.ophyer.cn/official_website/education/brandHonors-img-05.png'
        },
        {
          title: '福布斯中国新三板企业TOP100',
          img: 'https://h5.ophyer.cn/official_website/education/brandHonors-img-01.png'
        }
      ],
      mediaCoverage: [
        {
          logo: 'https://h5.ophyer.cn/official_website/education/mediaCoverage-logo1.png',
          url: 'https://baijiahao.baidu.com/s?id=1744746599769083079&wfr=spider&for=pc',
          time: '2022-09-23 14:21',
          newsTitle: '飞天云动汪磊：“元宇宙”尚处初级阶段 抬头逐梦仍需不忘本心',
          newList: [
            `编者按：中小企业好，中国经济才会好。优质中小企业既是推动经济高质量发展的重要驱动，也是提升产业链供应链稳定性和竞争力的重要力量。
            面对百年变局与世纪疫情交织叠加，积极培育“高精尖特”企业，打造更多“隐形冠军”，对于稳经济、育动能、稳就业具有重要意义。 近年来，随着我国不断加大培育工作力度，“专精特新”企业的发展取得明显成效。
            `
          ]
        },
        {
          logo: 'https://h5.ophyer.cn/official_website/education/mediaCoverage-logo2.png',
          url: 'https://mp.weixin.qq.com/s/ZyRtY0_Hh_v3zqMl-8fvvA',
          time: '2022-10-24 13:00',
          newsTitle: '飞天云动成功登陆港交所 AR/VR技术创新引领行业',
          newList: [
            `10月18日上午，北京飞天云动科技有限公司（下称“飞天云动”股票代码“6610.HK”)在港交所正式上市,成为港股元宇宙领域第一家上市公司。`,
            `头顶港股“元宇宙第一股”光环的飞天云动本次IPO发行2.71亿新股，每股2.21港元，发行市值约40亿港币，募资约6亿港币，基石占比30.33%`
          ]
        },
        {
          logo: 'https://h5.ophyer.cn/official_website/education/mediaCoverage-logo3.png',
          url: 'http://www.gov.cn/zhengce/2022-11/01/content_5723274.htm',
          time: '2022-11-01 20:38',
          newsTitle: '《虚拟现实与行业应用融合发展行动计划（2022—2026）解读',
          newList: [
            `近日，工业和信息化部、教育部、文化和旅游部、国家广播电视总局、国家体育总局等五部门联合发布《虚拟现实与行业应用融合发展
            行动计划（2022—2026年）》（工信部联电子〔2022〕148号，以下简称《行动计划》）。现就《行动计划》有关内容解读如下：</br>一、编制背景`,
          ]
        }
      ]
    }
  },
  mounted() {
    _this = this
    _this.setTimer()
  },
  methods: { 
    open(url) {
      window.open(url, '_blank')
    },
    setTimer:() =>{
      _this.timer = setInterval(() => {
        _this.advantageTab ++
      },3000)
    },
    mouseover:()=>{
      clearInterval(_this.timer)
    },
    mouseleave:() => {
      _this.setTimer()
    }
  }
}
</script>

<style lang="scss">
html,body {
  background: #161C28!important;
}
</style>

<style lang="scss" scoped>

.education {
  background: #20263D;
  min-width: 1376px;
}
.v-card {
  background: #20263D;
}
ul {
  margin: 0;
  padding: 0;
}

.banner{
  position: relative;
  overflow: hidden;
  min-width: 1376px;
  background: #191b1f;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    background: unset !important;
    height: 100%;
  }
  ::v-deep .v-image {
    background: #191b1f;
  }
  iframe {
    position: relative;
    z-index: 1;
    display: none;
  }
  #video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    -webkit-transition: opacity .5s;
    transition: opacity .5s;
    width: 100%;
  }

  .video-up {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(0,0,0,.0);
  }
  .banner-box {
    min-width: 1376px;
    max-width: 1376px;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 40px;
    .banner-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 3;
      img {
        width: 160px !important;
      }
      p {
        width: 180px;
        height: 40px;
        border-radius: 50px 50px 50px 50px;
        opacity: 0.8;
        border: 2px solid #FFFFFF;
        font-size: 18px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 37px;
        text-align: center;
      }
    }
    .banner-con {
      width: 700px;
      position: absolute;
      z-index: 3;
      color: #fff;
      top: 50%;
      transform: translate(0%, -50%) !important;
      opacity: 1;
    }
    .banner-title {
      width: 100%;
      font-size: 100px;
      font-family: Fontquan-XinYiGuanHeiTi-Regular, Fontquan-XinYiGuanHeiTi;
      font-weight: 400;
      line-height: 120px;
      background: linear-gradient(180deg, #FFFFFF 37%, rgba(84,152,255,0.55) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: -10px;
    }
    img {
      width: 587px;
      margin-bottom: 30px;
    }
    .banner-text {
      width: 100%;
      white-space: normal !important;
      font-size: 18px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 26px;
      margin-bottom: 30px;
    }
    
    .jt {
      width: 40px;
      position: absolute;
      left: 50%;
      transform: translate( -50%, 0%);
      bottom: 50px;
      // animation: ts 3s linear infinite;
      -webkit-animation: fudong 3s linear infinite;
      animation: fudong 3s linear infinite;
      z-index: 51;  
    }
    
  }

  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    z-index: 50;
    background-attachment: scroll;
    background-clip: border-box;
    background-color: transparent;
    background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(22,22,26,0)),color-stop(85%,#21263B));
    background-image: linear-gradient(rgba(22,22,26,0),#21263B 85%);
    background-origin: padding-box;
    background-size: auto;
  }
}

.banner-con {
  margin: 50px auto 0;
  max-width: 1100px;
  text-align: center;
  .banner-title {
    width: 100%;
    font-size: 100px;
    font-family: Fontquan-XinYiGuanHeiTi-Regular, Fontquan-XinYiGuanHeiTi;
    font-weight: 400;
    line-height: 120px;
    background: linear-gradient(180deg, #FFFFFF 37%, rgba(84,152,255,0.55) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -10px;
  }
  img {
    width: 587px;
    margin-bottom: 30px;
  }
  .banner-text {
    width: 100%;
    white-space: normal !important;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 28px;
    margin-bottom: 0;
    text-align: left;
  }
}
    

.public-titles {
  height: 80px;
  margin: 90px auto 0px;
  position: relative;
  text-align: center;
  padding-top: 0;
  margin-bottom: 65px;
  p {
    width: 100%;
    height: 20px;
    font-family: SourceHanSerifCN;
    // mask-image:-webkit-gradient(linear, 0 0, 0 bottom, from(#d8d5d5), to(rgba(0, 0, 255, 0)));
    position:absolute;
    left: 50%;
    top: 45px;
    z-index: 2;
    letter-spacing: 1px;
    transform: translate(-50%, 0);
    user-select: none;
    font-size: 16px;
    color: #888888;
    line-height: 20px;
  }
  div {
    position: relative;
    z-index: 3;
    span:nth-of-type(1) {
      display: block;
      font-size: 42px;
      font-weight: bold;
      color: #fff;
    }
  }
}

.education-warp {
  width: 100%;
  margin-bottom: 50px;
}

.warp1 {
  ul {
    padding: 0;
    display: flex;
    justify-content: center;
    li {
      margin: 0 16px;
      transition: .5s all cubic-bezier(0,0.2,0.2,1);
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .box {
    width: 320px;
    height: 380px;
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
    padding: 40px 40px;
    img {
      width: 200px;
    }
    .v-card__text {
      margin-top: 20px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 26px;
      padding: 0;
    }
  }
}

.warp2 {
  .carousel-container {
    margin: 0 auto;
    // width: 1200px;
    width: 100%;
    height: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    animation: section-fade-in 1s ease 0s 1 normal both;
    .coverleft{
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px 0px 0px 5px;
      background: linear-gradient(180deg, rgba(22,20,20,0) 0%, #000000 100%);
      position: absolute;
      bottom: 0;
      p {
        height: 100%;
        font-size: 24px;
        font-weight: 700;
        color: #FFFFFF;
        line-height: 150px;
        margin: 0;
      }
    }
    
    .c-item-1{
      margin-right: 32px;
      background-position: 0;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-1:not(.c-item-hover){
      // width: 240px;
      width: 220px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-cover {
      width: 220px;
      height: 400px;
      border-radius: 14px;
      //opacity: .6;
      transition: all .4s;
      display: flex;
      transition-timing-function: ease;
      position: relative;
    }
    .c-item-hover{
      width: 620px;
      .c-item-cover {
        width: 100%;
      }
    }
  }
}

.warp3 {
  .warp-box {
    background: #20263D;
    margin-bottom: -3px;
  }
  ::v-deep .v-tabs {
    height: 560px;
  }
  ::v-deep .v-tabs-bar {
    width: 340px;
    margin-right: 15px;
    background: #10112C !important;
    border-radius: 20px 0 0 20px;
    .v-tab {
      width: 100%;
      height: 140px;
      padding: 0 !important;
      margin: 0 !important;
      transition: .5s all cubic-bezier(0,0.2,0.2,1);
      text-align: left;
      &:hover {
        transform: scale(1.05);
      }
    }
    .v-tab:nth-of-type(1) {
      border-radius: 20px 0 0 20px;
      p {
        border-radius: 20px 0 0 0;
      }
    }
    .v-tab:nth-of-type(4) {
      border-radius: 20px 0 0 20px;
      p {
        border-radius: 0 0 0 20px;
      }
    }
    p {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: left;
      margin-bottom: 0;
      padding-left: 40px;
      span {
        font-weight: 700;
        color: #327EF7;
        letter-spacing: 0;
      }
      span:nth-of-type(1) {
        font-size: 50px;
        font-family: Arial-Bold, Arial;
      }
      span:nth-of-type(2) {
        width: 0;
        height: 0;
        border-left: 10px solid #327EF7;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        margin: 0 35px 0 10px;
      }
      span:nth-of-type(3) {
        font-size: 32px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      }
    }
    .v-tab--active p {
      height: 100%;
      background: linear-gradient(270deg, #0F1132 0%, #327EF7 100%);
      span {
        color: #FFFFFF;
      }
      span:nth-of-type(2) {
        border-left: 10px solid #FFFFFF;
      }
    }
  }

  ::v-deep .v-tabs-items {
    background: #20263D;
    position: relative;
    .lavbg {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 140px;
      background: linear-gradient(180deg, rgba(22,20,20,0) 0%, #000000 100%);
      padding-left: 50px;
      p {
        width: 650px;
        margin: 0;
        padding: 0;
      }
      p:nth-of-type(1) {
        font-size: 28px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
      }
      p:nth-of-type(2) {
        margin-top: 20px;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
      }
    }
  }
}

.warp4 {
  max-width: 1376px;
  margin: 0 auto 28px;
  .carousel-nav-ul {
    margin-top: -50px;
    display: flex;
    justify-content: center;
    margin-bottom: 115px;
    li {
      width: 200px;
      height: 50px;
      border-radius: 50px 50px 50px 50px;
      opacity: 1;
      border: 1px solid #FFFFFF;
      margin: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      box-sizing: border-box;
      cursor: pointer;
      img {
        width: 30px;
        margin-right: 5px;
      }
      span {
        font-size: 24px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
    .active {
      background: #327EF7;
      border: 1px solid #327EF7;
    }
  }
  .carousel-con-ul {
    margin-top: -30px;
    height: 430px;
    display: flex;
    justify-content: center;
    li {
      width: 320px;
      height: 420px;
      border-radius: 20px 20px 20px 20px;
      margin: 0 16px;
      position: relative;
      transition: .5s all cubic-bezier(0,0.2,0.2,1);
      img {
        width: 320px;
      }
      p {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background: linear-gradient(180deg, rgba(22,20,20,0) 0%, #000000 100%);
        font-size: 28px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #FFFFFF;
        text-align: center;
        padding: 0;
        margin: 0;
        line-height: 145px;
        border-radius: 0 0 20px 20px;
      }
      &:hover {
       transform: scale(1.05);
      }
    }
  }
  ::v-deep .v-carousel__controls {
    background: none;
    button {
      width: 16px;
      height: 16px;
      opacity: 1;
      border: 1px solid #888888;
      background: #20263D;
      box-sizing: border-box;
      margin: 0 14px;
      &::before {
        display: none;
      }
    }
    .v-btn__content {
      display: none;
    }
    .v-item--active {
      background: #327EF7;
      border: 1px solid #327EF7;
    }
  }
  .carousel-ul {
    min-width: 1376px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 10px;
    li {
      width: 220px;
      height: 100px;
      background: #FFFFFF;
      box-shadow: 0px 4px 15px 0px rgba(132,143,178,0.302);
      border-radius: 8px 8px 8px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 25px 50px;
      transition: .5s all cubic-bezier(0,0.2,0.2,1);
      &:hover {
       transform: scale(1.05);
      }
    }
    img {
      width: 265px;
      height: 145px;
    }
  }
  
}

.warp5 {
  .swiper-box {
    width: 1200px;
    margin: -10px auto;
    position: relative;
    .swiper-button-next {
      right: -85px;
      top: 45%;
      transition: .5s all cubic-bezier(0,0.2,0.2,1);
      img {
        width: 40px;
      }
      &::after {
        display: none;
      }
      &:hover {
        transform: scale(1.2);
      }
    }
    .swiper-button-prev {
      left: -85px;
      top: 45%;
      transition: .5s all cubic-bezier(0,0.2,0.2,1);
      img {
        width: 40px;
      }
      &::after {
        display: none;
      }
      &:hover {
        transform: scale(1.2);
      }
    }
    .swiper-container {
      position: unset;
      // width: 1200px !important;
      height: 100% !important;
      margin: 0 auto;
    }
    .swiper-slide {
      width: 320px;
      height: 500px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      transition: 300ms;
      .con-d {
        width: 320px;
        height: 420px;
        background-image: url("https://h5.ophyer.cn/official_website/education/brandHonors-bg.png");
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
       
        img {
          width: 100%;
        }
      }
      p {
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin: 20px 0 0 0;
      }
    }
  }
}

.warp6 {
  ul {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  li {
    width: 370px;
    height: 520px;
    background-image: url('https://h5.ophyer.cn/official_website/education/mediaCoverage-bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0 66.5px;
    position: relative;
    padding: 45px 35px;
    transition: .5s all cubic-bezier(0,0.2,0.2,1);
    border-radius: 20px 70px 20px 20px;
    p {
      color: #fff;
      font-weight: 700;
      margin: 0;
      padding: 0;
    }
    .time {
      position: absolute;
      right: 40px;
      top: 70px;
      font-size: 14px;
      font-family: Source Han Sans CN-Normal, Source Han Sans CN;
      font-weight: 350;
      color: #FFFFFF;
    }
    img {
      width: 130px;
    }
    .news-title {
      overflow: hidden;
      font-size: 22px;
      line-height: 32px;
      margin: 40px auto 30px;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .new-list {
      height: 170px;
      overflow: hidden;
      margin-bottom: 35px;
      p {
        font-size: 14px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 350;
        color: #FFFFFF;
        line-height: 24px;
        font-weight: 350;
      }
      p:nth-of-type(2) {
        margin-top: 28px;
      }
    }
    &:hover {
      // box-shadow: 0 0 30px rgb(38 137 243 / 48%);
      transform: scale(1.05);
      // transform: translateY(-24px);
    }
    button {
      width: 300px;
      height: 45px;
      background: #2D3C5D;
      border-radius: 5px 5px 5px 5px;
      font-size: 14px;
      font-family: Source Han Sans CN-Normal, Source Han Sans CN;
      font-weight: 350;
      color: #99A6BC;
      line-height: 45px;
      text-align: center;
    }
  }
  .li2 {
    img {
      margin-top: -10px;
      width: 286px;
    }
    .time {
      position: relative;
      left: 0;
      top: 60px;
    }
  }
}

footer {
  margin-top: 90px;
  width: 100%;
  // height: 356px;
  background: #101127;
  min-width: 1376px;
  box-sizing: border-box;
  .container {
    max-width: 1376px;
  }
}

.suspend-btn {
  position: fixed;
  bottom: 25%;
  right: 20px;
  z-index: 1001;
  ::v-deep .v-btn__content {
    white-space: initial;
    display: unset;
    flex: unset;
  }
  .hover-group {
    position: relative;
    .group-btn {
      width: 52px;
      height: 120px;
      padding-top: 10px;
      border-radius: 25px;
      box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
      background-color: #ffffff;
      cursor: pointer;
    }
    .hover-content {
      position: absolute;
      right: 72px;
      bottom: 0;
      .content-phone {
        width: 280px;
        height: 232px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
      }
      .content-follow {
        width: 250px;
        height: 270px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border-radius: 10px;
        padding-bottom: 10px;
        box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
      }
    }
  }
  .hover-btn {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    position: fixed;
    bottom: calc(20% - 20px);
    right: 20px;
    z-index: 5;
    box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
    cursor: pointer;
  }
}
</style>